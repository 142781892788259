import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Components/Header_Component/Header';
import Home from './Components/Home_Page/Home';
import Features from './Components/Features_Page/Features';
import About from './Components/About_Page/About';
import Testimonials from './Components/Testimonials_Page/Testimonials';
import Blogs from './Components/Blogs_Page/Blogs';
import BlogSingle from './Components/Blogs_Page/Blogs_Component/BlogSingle';
import Faq from './Components/FAQ_Page/Faq';
import ContactUs from './Components/ContactUs_Page/ContactUs';
import Footer from './Components/Footer_Component/Footer';
import Donate from './Components/Donate_Page/Donate';
import DonateForm from './Components/Donate_Form/DonateForm';
import Gdpr from './Components/Gdpr_Page/Gdpr';
import Legal from './Components/Legal_Page/Legal';

import { Outlet, Route, Routes } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import images from './Images';

import './style/responsive.css'
import React, { useEffect, useState } from 'react';

const stripePromise = loadStripe("pk_test_51PbijrRqCrMKUIvGtsRTv1XNM5N0uxw0sGDhCSRUvfSUdZmcZBSX06tcaqSp60OYUTOMVfmi4vFMZdwJVtXFFIUA00SB2KMjyB");

function App() {
  const [clientSecret, setClientSecret] = useState("");
  useEffect(() => {
    fetch("https://soapp.hodemoserver.in/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ amount: 1000 }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  useEffect(() => {
    if (clientSecret) console.log("Client Secret:", clientSecret);
  }, [clientSecret]);

  // const appearance = {
  //   theme: 'stripe',
  // };
  // const options = {
  //   clientSecret,
  //   appearance,
  // };

  return (
    <>
      <div className="bg">
        <video autoPlay loop muted className="w-100 video-background__video">
          <source src={images.StarPlatinum} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <Routes>
        <Route path="/" element={<> <Header /> <Outlet /> <Footer /> </>}>
          <Route index element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/future-features' element={<Features />} />
          <Route path='/testimonials' element={<Testimonials />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route path='/blog-single' element={<BlogSingle />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path="/support-us" element={<Donate />} />

          {/* <Route path="/DonateForm" element={
            { clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <DonateForm />
              </Elements>
            )}
            } /> */}
          <Route
            path="/donate-form"
            element={
              clientSecret ? (
                <Elements options={{ clientSecret }} stripe={stripePromise}>
                  <DonateForm />
                </Elements>
              ) : (
                <div className='loader'>Loading...</div>
              )
            }
          />
          <Route path='/gdpr' element={<Gdpr />} />
          <Route path='/terms-of-use' element={<Legal />} />
        </Route>
      </Routes>
    </>
  )
}

export default App;
